<template>
  <div>
    <b-card footer-tag="footer" footer-class="text-right">
      <b-row>
        <b-col xl="12" lg="12" md="12" sm="12" cols="12"> <ckeditor v-model="editorData"></ckeditor></b-col>
      </b-row>

      <template #footer>
        <b-button variant="primary" class="font-weight-bold px-10" @click="save()"> Save </b-button>
      </template>
    </b-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import CKEditor from 'ckeditor4-vue'
import DisclaimerServices from '../../../core/services/disclaimers'

export default {
  name: 'disclaimers-data-protection',
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Disclaimers' }])
    this.get()
  },
  components: {
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      show: 'dataProtection',
      editorData: '',
    }
  },
  methods: {
    save() {
      console.log(`this.editorData`, this.editorData)
    },
    async get() {
      const getAll = await DisclaimerServices.getAll()
      console.log(`getAll`, getAll)
    },
  },
}
</script>
